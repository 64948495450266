import { createSlice } from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode';

interface StateType {
  ubicacionContable?: string | any;
  ubicacionInventariable?: string | any;
  id_contable?: number| any;
  token?: string | any;
  user:string | any;
  idUsuario : number | any;
  userType : string | any;
  id_Imo : number | any;
  id_inventariable: number | any;
}

const initialState: StateType = {
    ubicacionContable: null,
    ubicacionInventariable: null,
    id_contable: null, 
    token: null,
    user: null,
    idUsuario: null,
    userType: null,
    id_Imo: null,
    id_inventariable: null,
  
};
export interface JwtPayloadAuth {
  user: string;
  idUsuario: number;
  username: string;
  userType: string;
  id_Imo: string;
  ubicacionContable: string| undefined;
  ubicacionInventariable:  string| undefined;
  idUbicacionContable: number| undefined;
  idUbicacionInventariable:  number| undefined;
}

export const sesionSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    setLocation: (state: StateType, action) => {
      state.ubicacionContable = action.payload.ubicacionContable;
      state.ubicacionInventariable = action.payload.ubicacionInventariable;
      state.id_contable =action.payload.contable;
      state.id_inventariable= action.payload.inventariable
    },
    setToken: (state: StateType, action) => {

      const decodedToken = jwtDecode(action.payload) as JwtPayloadAuth;;
      state.user = decodedToken.username;
      state.idUsuario = decodedToken.idUsuario;
      state.userType = decodedToken.userType;
      state.id_Imo = decodedToken.id_Imo;
      state.ubicacionContable = decodedToken.idUbicacionContable
      state.ubicacionInventariable =decodedToken.idUbicacionInventariable
      state.id_contable =action.payload.idUbicacionContable;
      state.id_inventariable= action.payload.idUbicacionInventariable
      state.token = action.payload;

    },
    closeSession: (state: StateType) => {
      state.user = null;
      state.idUsuario = null;
      state.userType = null;
      state.id_Imo = null;
      state.token = null;
      
    },
  },
});

export const {
    setLocation,
    setToken,
    closeSession,
} = sesionSlice.actions;

export default sesionSlice.reducer;

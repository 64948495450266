import  { useState,useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress'
import { getTicketPDFMutation } from 'src/utils/httpRequest/productosMutation';

const ComponentToPrint = () => {
  const [pdf, setPDF] = useState('');
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);


const {mutate} =getTicketPDFMutation();
  const setData = (base64:string) => {
    if(base64.length<5){
      setError(true);
      return;
    }
    setPDF("data:application/pdf;base64,"+base64); //Local  

  };


  useEffect(() => {
   
    if(!loaded){
      const storedTicket = window.localStorage.getItem('actualTicket');
      if (storedTicket) {
      
      

      mutate(storedTicket,{
        onSuccess:(data:any)=>{
     
          setData(data.data.message);
        }
      });
    }
        setLoaded(true);
    }
   
    
    return () => {
    
    };
  }, []);


  
  return (
   <>
   { pdf&&pdf.length>5?<object  onError={()=>{setError(true)}} style={{width:"100%",height:"99vh"}} data={pdf} type='application/pdf'></object>
      : error?<div style={{width:"100%",height:"99vh",display:"flex",justifyContent:"center",alignItems:"center"}}><h5>Something is wrong, try again</h5></div>:<div style={{width:"100%",height:"99vh",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress size={70}></CircularProgress></div> 
    }
    
   </>
  );
};

export default ComponentToPrint


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import ComponentToPrint from 'src/views/pages/printView';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));


/* ****Apps***** */
const Contacts = Loadable(lazy(() => import('../views/apps/contacts/Contacts')));
const Tickets = Loadable(lazy(() => import('../views/apps/tickets/Tickets')));
const Usuarios = Loadable(lazy(() => import('../views/apps/Usuarios/usuarios')));
const Ecommerce = Loadable(lazy(() => import('../views/apps/eCommerce/Ecommerce')));
const EcommerceDetail = Loadable(lazy(() => import('../views/apps/eCommerce/EcommerceDetail')));
const EcomProductList = Loadable(lazy(() => import('../views/apps/eCommerce/EcomProductList')));
const TokenView = Loadable(lazy(() => import('../views/apps/Tokens/Token')));


const Protocolos = Loadable(lazy(() => import('../views/apps/Protocolos/protocolos')));
const AltaProtocolos = Loadable(lazy(() => import('../views/apps/Protocolos/altaProtocolos')));

const EcomProductCheckout = Loadable(
  lazy(() => import('../views/apps/eCommerce/EcommerceCheckout')),
);

const AccountSetting = Loadable(
  lazy(() => import('../views/pages/configPage/Configuracion')),
);

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/ForgotPassword2')),
);

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

const Versiones = Loadable(lazy(() => import('../views/apps/versiones')));




const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/apps/expedientes" /> },
      { path: '/apps/expedientes', element: <Contacts /> },
      { path: '/apps/tickets', element: <Tickets /> },
      { path: '/apps/usuarios', element: <Usuarios /> },
      { path: '/apps/protocolos', element: <Protocolos /> },
      { path: '/apps/alta-protocolos', element: <AltaProtocolos /> },

      { path: '/apps/ecommerce/shop', element: <Ecommerce /> },
      { path: '/apps/ecommerce/eco-product-list', element: <EcomProductList /> },
      { path: '/apps/ecommerce/eco-checkout', element: <EcomProductCheckout /> },
      { path: '/apps/ecommerce/detail/:id', element: <EcommerceDetail /> },
      { path: '/pages/account-settings', element: <AccountSetting /> },
      { path: '/pages/token', element: <TokenView /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/versiones', element: <Versiones /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '/print', element: <ComponentToPrint/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
